import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UseCases])} />);

// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './dating_sites.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import {getImagesFluid, renderImageByFileName, viewports} from '../../components/componentsUtils';
// import useBreakpoint from "../../components/hooks/useBreakpoint";
// import CoveredVideo from "../../components/shared/CoveredVideo/CoveredVideo";
// import SlideTypeF from "../../components/shared/ImageSlider/SlideTypeF";
// import Movie from "../../movies/Inspirations/DatingSites/inspirations-dating-websites.mp4";
// import InspirationsSlideTitle from "../../components/shared/ImageSlider/InspirationsSlideTitle";
// import DatingSitesIconBlack from "../../images/icons/dating_b.svg";
// import GoToButton from "../../components/shared/GoToButton/GoToButton";
// import {getId, getLink, getRoutes} from "../../components/shared/routes";
// import BackgroundImg from "../../images/home/green_background.svg";
// import {graphql, useStaticQuery} from 'gatsby';

// const query = graphql`
// {
//   fluid: allFile(filter: {sourceInstanceName: {eq: "images_DatingSites"}}) {
//     nodes {
//       childImageSharp {
//         fluid(maxWidth: 1090, quality: 60) {
//           ...GatsbyImageSharpFluid,
//           originalName
//         }
//       }
//     }
//   }
// }`;

// const DatingSitesPage = () => {
//     const { t } = useTranslation();
//     const pageTitle = `${getRoutes().DatingSites.pageTitle} | ${getRoutes().Inspirations.pageTitle}`;

//     const imagesFluid = getImagesFluid(useStaticQuery(query));
//     const breakpoint = useBreakpoint();

//     const getSlide2ImageWrapperStyles = () => {
//         switch (breakpoint.viewport) {
//         case viewports.tabletViewport:
//             return {
//             width: 'calc(100% - 10px)',
//             marginLeft: 'auto',
//             marginRight: 'auto',
//             marginTop: '0',
//             marginBottom: '0'
//             };
//         default:
//             return {
//             width: '56%',
//             marginLeft: '60px',
//             marginRight: '10px'
//             };
//         }
//     };

//     const getSlide3 = () => {
//         const title = "The right matching!";
//         const text1 = <p>Modern quality photo is a reflection of your personality.</p>;
//         const text2 = <p>"What matters more than <br/>anything when it comes to <br/>your online dating profile? <br/>One word: Photos. <br/>Photos can drastically <br/>increase your chances of <br/>getting more communication <br/>and dates. Or not." <br/>| - eHarmony</p>;
//         const alt = "dating sites right matching";
//         if (breakpoint.viewport === viewports.phoneViewport) {
//             return (
//                 <SlideTypeF
//                     title={title}
//                     text1={text1}
//                     text2={<div className={css.slide3Text2}>{text2}</div>}
//                     phoneImage1={'Dating_sites_mobile_4.jpg'}
//                     phoneImage1Alt={alt}
//                     phoneImage1Height={340}
//                     phoneImage2={'Dating_sites_mobile_5.jpg'}
//                     phoneImage2Alt={alt}
//                     phoneImage2Height={280}
//                     imagesFluid={imagesFluid}
//                     fullImageWrapperStyles={getSlide2ImageWrapperStyles()}
//                 />
//             )
//         }
//         return (
//             <div className={css.slide3FullWrapper}>
//               <div className={css.slide3FullWrapperInner}>
//                   <div className={css.title}>
//                       <InspirationsSlideTitle title={title} />
//                   </div>
//                   <div className={css.content}>
//                       <img
//                           className={css.background}
//                           src={BackgroundImg}
//                           alt={""}
//                       />
//                       <div className={css.imagesWrapper}>
//                           <div className={css.imageLeftWrapper}>
//                               {renderImageByFileName(imagesFluid, 'Dating_Sites_3_left.png', alt)}
//                           </div>
//                           <div className={css.imageRightWrapper}>
//                               {renderImageByFileName(imagesFluid, 'Dating_Sites_3_right.png', alt)}
//                           </div>
//                       </div>
//                       <div className={css.textWrapper}>
//                           <div>
//                               <div className={css.slide3TextWrapper}>
//                                   <div className={css.slide3Text1}>
//                                       {text1}
//                                   </div>
//                                   <div className={css.slide3Text2}>
//                                       {text2}
//                                   </div>
//                               </div>
//                           </div>
//                       </div>
//                   </div>
//               </div>
//             </div>
//           )
//       };

//     return (
//     <Layout>
//         <SEO title={pageTitle} description={'Pro image quality using a selfie shot'}/>

//         <div className={css.wrapper}>

//         <div className={css.private}>
            


//         <div className={css.wrapper}>
//             <div className={css.slide1}>
//             <CoveredVideo
//                 videoSrcURL={Movie}
//                 placeholderImage={'Dating_Sites_1_placeholder.jpg'}
//                 imagesFluid={imagesFluid}
//                 icon={DatingSitesIconBlack}
//                 title="Dating Sites"
//                 text="You must love this photo to attract attention."
//                 buttons={(
//                     <GoToButton
//                         sectionUrl={getLink([getRoutes().DatingSites, getRoutes().DatingSites.sections.getStarted])}
//                         text="Get started!"
//                     />
//                 )}
//             />
//             </div>
//             <div className={css.slide2} id={getRoutes().DatingSites.sections.getStarted}>
//             <SlideTypeF
//                 title={<>Amazing professional quality <br/>of posted photos!</>}
//                 text1={<p>No need for a professional photographer!</p>}
//                 text2={<p>Selfies, smartphone shots are seamlessly <br/>processed in real time.</p>}
//                 fullImage={'dating_sites_2.jpg'}
//                 fullImageAlt={'dating sites'}
//                 phoneImage1={'Dating_sites_mobile_1.jpg'}
//                 phoneImage1Alt={'dating sites'}
//                 phoneImage1Height={330}
//                 phoneImage2={'Dating_sites_mobile_2.jpg'}
//                 phoneImage2Alt={'dating sites'}
//                 phoneImage2Height={170}
//                 phoneImage3={'Dating_sites_mobile_3.jpg'}
//                 phoneImage3Alt={'dating sites'}
//                 phoneImage3Height={175}
//                 imagesFluid={imagesFluid}
//                 imagePosition={'right'}
//                 fullImageWrapperStyles={getSlide2ImageWrapperStyles()}
//             />
//             </div>
//             <div className={css.slide3}>
//                 {getSlide3()}
//             </div>
//         </div>

//         </div>

//         <div className={css.Footer}>
//             <Footer/>
//         </div>

//         </div>
//     </Layout>
//     );
//     };

// export default DatingSitesPage;
